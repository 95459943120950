import React, { Component } from 'react';
import { CultivationMasterData } from '../../models/Fieldmanager/cultivationMasterData';
import HttpService from '../../services/HttpService';
import { ListSelectItem } from '../abstract/ListSelect';
import { MultiListSelect } from '../abstract/MultiListSelect';
import { Loading } from '../Loading';

interface IProps {
    onSelectionChanged: (selected: CultivationMasterData[]) => void;
}

interface IState {
    loading: boolean;
    data?: CultivationMasterData[];

    selected: string[];
}

export class SelectCultivation extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = { loading: true, selected: [], };
    }

    public render() {
        return this.state.loading
            ? <Loading />
            : this.renderSelector();
    }

    public componentDidMount() {
        this.populateData();
    }

    private async populateData() {
        const data = await HttpService.getPaged<CultivationMasterData>('/api/fieldmanager/cultivationmasterdata');
        this.setState({ data, loading: false });
    }

    private renderSelector() {
        const items = this.state.data!.map((item) => ({
            key: item.id,
            value: <>
                {item.name}
                <div className='text-muted'>
                    <small>
                        <strong>{item.type}</strong>{' '}
                        Einheit: {item.unit}, Gruppe: {item.group}
                    </small>
                </div>
            </>
        }));

        return (<>
            <small>Mehrfachauswahl ist möglich.</small>
            <MultiListSelect items={items} onSelected={this.onCultivationSelected.bind(this)} />
            <div className='d-flex justify-content-between mt-3'>
                <div></div>
                <div>
                    <button className='btn btn-primary' onClick={this.onConfirmSelection.bind(this)} disabled={this.state.selected.length <= 0}>
                        Weiter &raquo;
                    </button>
                </div>
            </div>
        </>);
    }

    private onCultivationSelected(selection: ListSelectItem<string>[]) {
        this.setState({ selected: selection.map((item) => item.key) });
    }

    private onConfirmSelection() {
        const selectedIds = new Set(this.state.selected);
        this.props.onSelectionChanged(this.state.data!.filter((item) => selectedIds.has(item.id)));
    }
}
