import React, { Component } from 'react';
import { RouteChildrenProps, RouteComponentProps } from 'react-router-dom';
import { Mandator } from '../../models/Invoicemanager/mandator';
import HttpService from '../../services/HttpService';
import { ListSelect, ListSelectItem } from '../abstract/ListSelect';
import { Loading } from '../Loading';
import { getMandatorId, MandatorParams } from './MandatorParams';

interface IState {
    loading: boolean;
    mandators?: Map<number, Mandator>;
    selected: Mandator | null;
}

export class ChangeMandator extends Component<RouteChildrenProps<MandatorParams>, IState> {
    constructor(props: RouteChildrenProps<MandatorParams>) {
        super(props);

        this.state = { loading: true, selected: null };
    }

    public render() {
        return this.state.loading
            ? <Loading />
            : this.renderSelector();
    }

    public componentDidMount() {
        this.populateData();
    }

    private async populateData() {
        const mandatorsList = await HttpService.get<Mandator[]>('/api/invoicemanager/mandators/confidential/0');
        const mandators = new Map<number, Mandator>(mandatorsList?.map((mandator) => [mandator.id, mandator]));
        const curMandatorId = this.getCurrentMandatorId();
        const selected = (curMandatorId ? mandators.get(curMandatorId) : null) || null;

        this.setState({ mandators, selected, loading: false });
    }

    private renderSelector() {
        const selectors: ListSelectItem<number>[] = [];
        this.state.mandators!.forEach((mandator) => selectors.push({
            key: mandator.id,
            value: mandator.name,
        }));

        return <>
            <h4>Mandant wählen</h4>
            <ListSelect
                items={selectors}
                preSelectedKey={this.state.selected?.id}
                onSelected={this.onMandatorSelected.bind(this)} />
        </>;
    }

    private onMandatorSelected(item: ListSelectItem<number>) {
        const mandator = this.state.mandators!.get(item.key) || null;
        const pathSegment = mandator ? mandator.id + '/' : '';

        this.props.history.push('/invoicemanager/' + pathSegment);
    }

    private getCurrentMandatorId() {
        return getMandatorId(this.props.match?.params);
    }
}

export function ChangeMandatorWithOptionalMandator(props: RouteComponentProps<MandatorParams>) {
    return <ChangeMandator key={props.match?.params.mandatorId} {...props} />
}
