import React, { Component } from 'react';
import { ListGroup } from 'reactstrap';
import { Address } from '../../models/address';
import HttpService from '../../services/HttpService';
import Utils from '../../services/Utils';
import { NavigationListGroupItem } from '../abstract/NavigationListGroupItem';
import { Loading } from '../Loading';

interface IProps {
}

interface IState {
    loading: boolean;
    addresses: Address[];
}


function getValueOrDefault(value?: string, defaultValue: string = '-') {
    if (value) {
        return value;
    } else {
        return defaultValue;
    }
}

export class AddressList extends Component<IProps, IState> {
    static displayName = AddressList.name;

    constructor(props: IProps) {
        super(props);
        this.state = { addresses: [], loading: true };
    }

    componentDidMount() {
        this.populateData();
    }

    static renderEntries(addresses: Address[]) {
        return addresses.map((address =>
            <NavigationListGroupItem key={address.id} to={'/addresses/' + address.id} className='non-link'>
                <strong>{getValueOrDefault(Utils.addressDisplayName(address)) + ', ' + address.city}</strong>
                <div className='text-muted'>
                    <small>{getValueOrDefault(address.mobile) + ', ' + getValueOrDefault(address.email)}</small>
                </div>
            </NavigationListGroupItem>));
    }

    public render() {
        if (this.state.loading) {
            return <Loading />
        }

        return <>
            <div className='my-2'>
                Adresse zum Bearbeiten anklicken/antippen.
            </div>
            <ListGroup>{AddressList.renderEntries(this.state.addresses)}</ListGroup>
        </>;
    }

    async populateData() {
        const addresses = await HttpService.getPaged<Address>('/api/addresses');
        this.setState({ addresses, loading: false });
    }
}
