import { format } from 'date-fns/esm';
import React, { Component } from 'react';
import { RouterProps, useHistory } from 'react-router-dom';
import { AddressMin } from '../../models/address';
import { ArticleWithUsage } from '../../models/Invoicemanager/article';
import { OrderType } from '../../models/Invoicemanager/orderType';
import { Staff } from '../../models/staff';
import HttpService from '../../services/HttpService';
import LocalizationService from '../../services/LocalizationService';
import Utils from '../../services/Utils';

interface IProps {
    mandatorId: number;
    date?: Date;
    staff?: Staff;
    description?: string;
    remarks?: string;
    orderType?: OrderType;
    customer?: AddressMin;
    articles: ArticleWithUsage[];
}

type Props = RouterProps & IProps;

interface IState {
    saving: boolean;
}

class ConfirmNewOrderWithHistory extends Component<Props, IState> {
    constructor(props: Props) {
        super(props);

        this.state = { saving: false, };
    }

    public render() {
        const date = this.props.date ?
            <>{format(this.props.date!, 'PPP', { locale: LocalizationService.de })}</> : <>nicht gewählt</>;

        return <div>
            <div className='row'>
                <div className='col-sm-4 col-md-2'><strong>Datum</strong></div>
                <div className='col-sm-8 col-md-10'>{date}</div>
            </div>
            <div className='row mt-2'>
                <div className='col-sm-4 col-md-2'><strong>Personal</strong></div>
                <div className='col-sm-8 col-md-10'>
                    {this.props.staff?.displayName}
                </div>
            </div>
            <div className='row mt-2'>
                <div className='col-sm-4 col-md-2'><strong>Bezeichnung</strong></div>
                <div className='col-sm-8 col-md-10'>
                    {this.props.description}
                </div>
            </div>
            <div className='row mt-2'>
                <div className='col-sm-4 col-md-2'><strong>Bemerkung</strong></div>
                <div className='col-sm-8 col-md-10'>
                    {this.props.remarks}
                </div>
            </div>
            <div className='row mt-2'>
                <div className='col-sm-4 col-md-2'><strong>Typ</strong></div>
                <div className='col-sm-8 col-md-10'>
                    {this.props.orderType?.name}
                </div>
            </div>
            <div className='row mt-2'>
                <div className='col-sm-4 col-md-2'><strong>Kunde</strong></div>
                <div className='col-sm-8 col-md-10'>
                    {Utils.addressDisplayNameWithCity(this.props.customer)}
                </div>
            </div>
            <div className='row mt-2'>
                <div className='col-sm-4 col-md-2'><strong>Artikel / Dienstleistungen</strong></div>
                <div className='col-sm-8 col-md-10'>
                    {ConfirmNewOrderWithHistory.renderArticlesSummary(this.props.articles)}
                </div>
            </div>
            <div className='row mt-3'>
                <div className='col-12'>
                    <button className='btn btn-primary' onClick={this.create.bind(this)}
                        disabled={!ConfirmNewOrderWithHistory.canSave(this.state, this.props)}>
                        Hinzufügen
                    </button>
                </div>
            </div>
        </div>;
    }

    private static canSave(state: IState, props: IProps) {
        return !state.saving && // When a write operation is already ongoing, do not allow another one.
            props.date && props.staff &&
            props.articles.length > 0 &&
            undefined === props.articles.find((item) => !item.amount || !item.price);
    }

    private static renderArticlesSummary(articles: ArticleWithUsage[]) {
        const cultivations = articles.map((article) =>
            <li key={article.id}>
                {article.name + ' '}
                <small>
                    (
                    {article.amount} {article.unit}
                    , Preis: {article.price} CHF
                    {article.discount ? <>, {article.discount}% Rabatt</> : <></>}
                    )
                </small>
            </li>);

        return <ul className='simple-list'>
            {cultivations}
        </ul>;
    }

    private async create() {
        const creationRequest = {
            date: this.props.date,
            orderTypeId: this.props.orderType!.id,
            addressId: this.props.customer!.id,
            staffId: this.props.staff!.id,
            description: this.props.description,
            remarks: this.props.remarks,
            articles: this.props.articles.map((article) => ({
                id: article.id,
                amount: article.amount,
                price: article.price,
                discount: article.discount,
            })),
        };

        this.setState({ saving: true });

        try {
            await HttpService.postJson(
                '/api/Invoicemanager/mandators/confidential/0/' + this.props.mandatorId + '/orders/new', creationRequest);
            this.props.history.push('../');
        } catch (error) {
            console.error('failed to write entries', error);
        } finally {
            this.setState({ saving: false });
        }
    }
}

export function ConfirmNewOrder(props: IProps) {
    return <ConfirmNewOrderWithHistory {...props} history={useHistory()} />;
}
