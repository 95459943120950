import React, { Component } from 'react';
import { Col, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Label } from 'reactstrap';
import { ArticleUsage, ArticleWithUsage } from '../../models/Invoicemanager/article';

interface IProps {
    articles: ArticleWithUsage[];
    onChanged: (articlesWithUsage: ArticleWithUsage[]) => void;
}

interface IState {
    articles: ArticleWithUsage[];
    usages: ArticleUsage[];
}

export class SetArticlesDetails extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = SetArticlesDetails.getDerivedStateFromProps(props) as IState;
    }

    public static getDerivedStateFromProps(nextProps: IProps, curState?: IState): IState | Partial<IState> {
        let result: Partial<IState> = { articles: nextProps.articles };
        let resetUsages = false;

        if (curState && curState.usages) {
            // TODO: Perhaps also consider verifying IDs of articles.
            //       The selection might have changed, but the count might be the same.
            if (curState.usages.length !== nextProps.articles.length) {
                resetUsages = true;
            }
        } else {
            resetUsages = true;
        }

        if (resetUsages) {
            result.usages = nextProps.articles.map((article, index) => ({
                amount: article.amount ? article.amount : 0,
                price: article.price ? article.price : 0,
                discount: article.discount ? article.discount : 0,
            }));
        }

        return result;
    }

    public render() {
        const forms = this.state.articles.map(this.renderArticleForm.bind(this));

        return <>
            Bitte ergänzen Sie.
            <ul className='simple-list mt-3'>
                {forms}
            </ul>
            <div className='d-flex justify-content-between mt-3'>
                <div></div>
                <div>
                    <button className='btn btn-primary' onClick={this.onConfirmSelection.bind(this)}
                        disabled={!SetArticlesDetails.canContinue(this.state)}>
                        Weiter &raquo;
                    </button>
                </div>
            </div>
        </>;
    }

    private renderArticleForm(article: ArticleWithUsage, index: number) {
        const usage = this.state.usages[index];

        return (<li key={article.id}>
            <h6>{article.name}</h6>
            <FormGroup row className='mb-1'>
                <Label for={'amount-' + article.id} xs={6} size='sm'>Menge</Label>
                <Col xs={6}>
                    <InputGroup size='sm'>
                        <Input type='number' name={'a' + index.toString()} id={'amount-' + article.id}
                            step={0.1} min={0} value={usage.amount}
                            onChange={this.handleAmountChange.bind(this)} />
                        <InputGroupAddon addonType='append'>
                            <InputGroupText>{article.unit}</InputGroupText>
                        </InputGroupAddon>
                    </InputGroup>
                </Col>
            </FormGroup>
            <FormGroup row className='mb-1'>
                <Label for={'price-' + article.id} xs={6} size='sm'>Preis pro {article.unit}</Label>
                <Col xs={6}>
                    <InputGroup size='sm'>
                        <Input type='number' name={'p' + index.toString()} id={'price-' + article.id}
                            step={0.01} min={0} value={usage.price || 0}
                            onChange={this.handlePriceChange.bind(this)} />
                        <InputGroupAddon addonType='append'>
                            <InputGroupText>CHF</InputGroupText>
                        </InputGroupAddon>
                    </InputGroup>
                </Col>
            </FormGroup>
            <FormGroup row className='mb-1'>
                <Label for={'discount-' + article.id} xs={6} size='sm'>Rabatt</Label>
                <Col xs={6}>
                    <InputGroup size='sm'>
                        <Input type='number' name={'d' + index.toString()} id={'discount-' + article.id}
                            step={1} min={0} max={100} value={usage.discount || 0}
                            onChange={this.handleDiscountChanged.bind(this)} />
                        <InputGroupAddon addonType='append'>
                            <InputGroupText>%</InputGroupText>
                        </InputGroupAddon>
                    </InputGroup>
                </Col>
            </FormGroup>
        </li>);
    }

    private handleAmountChange(ev: React.ChangeEvent<HTMLInputElement>) {
        const index = Number(ev.target.name.substr(1));
        const usages = this.state.usages;

        usages[index].amount = Math.max(0, ev.target.valueAsNumber);
        this.setState({ usages });
    }

    private handlePriceChange(ev: React.ChangeEvent<HTMLInputElement>) {
        const index = Number(ev.target.name.substr(1));
        const usages = this.state.usages;

        usages[index].price = Math.max(0, ev.target.valueAsNumber);
        this.setState({ usages });
    }

    private handleDiscountChanged(ev: React.ChangeEvent<HTMLInputElement>) {
        const index = Number(ev.target.name.substr(1));
        const usages = this.state.usages;

        usages[index].discount = Math.max(0, Math.min(100, ev.target.valueAsNumber));
        this.setState({ usages });
    }

    private static canContinue(state: IState) {
        return undefined === state.usages.find((usage) =>
            !usage.amount || usage.amount <= 0 ||
            !usage.price || usage.price <= 0 ||
            (usage.discount !== undefined && (usage.discount < 0 || usage.discount > 100)));
    }

    private onConfirmSelection() {
        const articles = this.state.articles;
        const usages = this.state.usages;

        articles.forEach((article, index) => {
            article.amount = usages[index].amount;
            article.price = usages[index].price;
            article.discount = usages[index].discount;
        });

        this.setState({ articles }, () => this.props.onChanged(this.state.articles));
    }
}
