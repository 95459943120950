import Axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import * as Auth from '../Auth';
import EventsService, { IEventRegistration } from './EventsService';

export interface IServerRequestsUpdate {
    numPending: number;
}

export const EventServerRequestsUpdate = 'serverRequestsUpdate';

class HttpService {
    private pendingRequests: AxiosRequestConfig[] = [];
    private serverRequestsEvent?: IEventRegistration<IServerRequestsUpdate>;

    constructor() {
        this.serverRequestsEvent = EventsService.registerEvent<IServerRequestsUpdate>(EventServerRequestsUpdate);

        Axios.defaults.validateStatus = () => true; // Always resolve promises with responses.
        Axios.interceptors.request.use(this.onInterceptRequest.bind(this));
        Axios.interceptors.response.use(this.onInterceptResponse.bind(this));
    }

    public async getPaged<T>(url: string, take?: number) {

        const params = { take };
        const response = await Axios.get<T[]>(url, { params });

        return response.data;
    }

    public async get<T>(url: string) {
        const response = await Axios.get<T>(url);

        switch (response.status) {
            case 404:
                return undefined;
        }

        return response.data;
    }

    public putJson<T>(url: string, value: T) {
        return Axios.put(url, value);
    }

    public postJson<T>(url: string, value: T) {
        return Axios.post(url, value);
    }

    private onInterceptRequest(config: AxiosRequestConfig) {
        this.pendingRequests.push(config);
        this.serverRequestsEvent?.raise({ numPending: this.pendingRequests.length })

        return config;
    }

    private onInterceptResponse(response: AxiosResponse) {
        if (response.status === 401) {
            Auth.raiseUnauthenticated();
        }

        const index = this.pendingRequests.findIndex((config) => response.config === config);

        if (index >= 0) {
            this.pendingRequests.splice(index, 1);
            this.serverRequestsEvent?.raise({ numPending: this.pendingRequests.length })
        } else {
            console.error('Could not find pending request for', response);
        }

        return response;
    }
}

export default new HttpService();
