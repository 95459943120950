import React, { Component } from 'react';
import { Button, Col, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Label } from 'reactstrap';
import { LandWithPartialArea } from '../../models/Fieldmanager/land';

interface IProps {
    lands: LandWithPartialArea[];
    onSelectionChanged: (selected: LandWithPartialArea[]) => void;
}

interface IState {
    lands: LandWithPartialArea[];
}

export class SetPartialAreas extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = { lands: props.lands, };
    }

    public static getDerivedStateFromProps(nextProps: IProps, prevState: IState) {
        return { lands: nextProps.lands, };
    }

    public render() {
        const forms = this.state.lands.map((land, index) => <li key={land.id}>
            <h6>{land.name}</h6>
            <FormGroup row>
                <Label for={'partialArea-' + land.id} xs={3} size='sm'>Teilfläche</Label>
                <Col xs={9}>
                    <InputGroup size='sm'>
                        <Input type='number' name={index.toString()} id={'partialArea-' + land.id}
                            step={1} min={0} max={land.area} value={land.partialArea || ''}
                            onChange={this.handleChange.bind(this)} />
                        <InputGroupAddon addonType="append">
                            <InputGroupText>a von {land.area} a</InputGroupText>
                            <Button size='sm' color='warning' onClick={(ev) => this.removePartial(ev, index)}
                                disabled={undefined === land.partialArea}>
                                Entfernen
                                </Button>
                        </InputGroupAddon>
                    </InputGroup>
                </Col>
            </FormGroup>
        </li>);
        const totalArea = this.state.lands.reduce((sum, land) => sum += land.partialArea || land.area, 0);
        const landSummary = <strong>Total {totalArea} a</strong>;

        return <>
            Bitte wählen Sie die Teilflächen für die ausgewählten Böden.
            <ul className='simple-list mt-3'>
                {forms}
            </ul>
            {landSummary}
            <div className='d-flex justify-content-between mt-3'>
                <div></div>
                <div>
                    <button className='btn btn-primary' onClick={this.onConfirmSelection.bind(this)}>Weiter &raquo;</button>
                </div>
            </div>
        </>;
    }

    private handleChange(ev: React.ChangeEvent<HTMLInputElement>) {
        const index = Number(ev.target.name);
        const lands = this.state.lands;
        lands[index].partialArea = Math.max(0, Math.min(lands[index].area, ev.target.valueAsNumber));
        this.setState({ lands });
    }

    private removePartial(ev: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) {
        ev.preventDefault();
        const lands = this.state.lands;
        lands[index].partialArea = undefined;
        this.setState({ lands });
    }

    private onConfirmSelection() {
        this.props.onSelectionChanged(this.state.lands);
    }
}
