import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import { Icon, IconName } from '../../icons/Icon';
import { AddressForm } from './AddressForm';
import { AddressList } from './AddressList';

export class Addresses extends Component {
    public render() {
        return <>
            <h1 id='pageLabel'>
                <Icon icon={IconName.Addresses} />
                Adressen
            </h1>
            <Switch>
                <Route path='/addresses/:id/' component={AddressForm} />
                <Route path='/addresses/' component={AddressList} />
            </Switch>
        </>;
    }
}