import 'bootstrap/dist/css/bootstrap.css';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import * as Auth from './Auth';
import { Loading } from './components/Loading';
import Login from './Login';
import LocalizationService from './services/LocalizationService';
import LoginService from './services/LoginService';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

// BEGIN Polyfills for IE11 support.
require('es6-promise').polyfill();
require('es6-object-assign').polyfill();
// END Polyfills for IE11 support.

LocalizationService.setDefault();

class MainComponent extends Component<{}, { loading: boolean, loggedIn: boolean }> {
    constructor(props: {}) {
        super(props);
        this.state = { loading: true, loggedIn: false };
        Auth.addEventListener('onUnauthenticated', () => this.setLoggedIn(false));
    }

    public render() {
        if (this.state.loading) {
            return <div className='login'>
                <div className='form-signin'><Loading /></div>
            </div>;
        }

        return (<Auth.AuthContext.Provider value={{ isLoggedIn: this.state.loggedIn, setLoggedIn: this.setLoggedIn.bind(this) }}>
            {this.state.loggedIn ? <><App /></> : <Login />}
        </Auth.AuthContext.Provider >);
    }

    public componentDidMount() {
        LoginService.getUser().then(
            () => this.setState({ loading: false, loggedIn: true }),
            () => this.setState({ loading: false, loggedIn: false }));
    }

    private setLoggedIn(loggedIn: boolean) {
        this.setState({ loggedIn });
    }
}

ReactDOM.render(
    <BrowserRouter basename={baseUrl || ''}>
        <MainComponent />
    </BrowserRouter>,
    rootElement);
