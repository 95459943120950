import React, { Component } from 'react';
import { ListGroup, ListGroupItem } from 'reactstrap';

export interface ListSelectItem<TKey> {
    key: TKey;
    value: string | React.ReactNode;
}

interface IProps<TKey> {
    hideArrows?: boolean;
    items: ListSelectItem<TKey>[];
    preSelectedKey?: TKey;
    onSelected: (item: ListSelectItem<TKey>) => void;
}

interface IState<TKey> {
    selectedKey?: TKey;
}

export class ListSelect<TKey extends string | number = string | number> extends Component<IProps<TKey>, IState<TKey>> {
    constructor(props: IProps<TKey>) {
        super(props);

        this.state = { selectedKey: props.preSelectedKey };
    }

    public render() {
        const listItems = this.props.items.map((item) =>
            <ListGroupItem key={item.key} onClick={() => this.onItemSelected(item)}
                active={this.state.selectedKey === item.key}
                className='selectable d-flex justify-content-between'>
                <div>{item.value}</div>
                {this.props.hideArrows ? <></> : <div>&raquo;</div>}
            </ListGroupItem>);

        return <ListGroup>
            {listItems}
        </ListGroup>;
    }

    private onItemSelected(item: ListSelectItem<TKey>) {
        this.props.onSelected(item);
        this.setState({ selectedKey: item.key });
    }
}
