export interface MandatorParams {
    mandatorId?: string;
}

export function getMandatorId(params?: MandatorParams) {
    if (params?.mandatorId) {
        return Number(params.mandatorId);
    }

    return null;
}
