import { Field, Form, Formik } from 'formik';
import React, { Component } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Address } from '../../models/address';
import { Country } from '../../models/country';
import HttpService from '../../services/HttpService';
import { Loading } from '../Loading';

interface IProps {
    id: string;
}

interface IState {
    loading: boolean;
    address?: Address;
    countries?: Country[];
}

interface IControlBase {
    id: string;
    label: string;
    children: any;
}

type IControl = IControlBase & any;

// TODO: Dedicated component for sharing across multiple forms.
function FrmControl({ id, label, children, ...addtlProps }: IControl) {
    return (<div className='form-group'>
        <label htmlFor={id}>{label}</label>
        <Field name={id} id={id} {...addtlProps}>{children}</Field>
    </div>);
}

function Checkbox({ id, label, ...addtlProps }: IControl) {
    return (<div className='form-check form-check-inline'>
        <Field type='checkbox' name={id} id={id} className='form-check-input' {...addtlProps} />
        <label className='form-check-label' htmlFor={id}>{label}</label>
    </div>);
}

export class AddressForm extends Component<RouteComponentProps<IProps>, IState> {
    static displayName = AddressForm.name;

    constructor(props: RouteComponentProps<IProps>) {
        super(props);
        this.state = { loading: true };
    }

    public render() {
        let contents = this.state.loading
            ? <Loading />
            : this.renderAddressForm(this.state.address as Address, this.state.countries as Country[]);

        return (
            <div>
                <h2>Adresse bearbeiten</h2>
                {contents}
            </div>
        );
    }

    public componentDidMount() {
        this.populateData();
    }

    private renderAddressForm(address: Address, countries: Country[]) {
        const countriesOptions = countries.map((country) => (
            <option key={country.id} value={country.id}>{country.name}</option>
        ));

        return <Formik
            initialValues={address}
            onSubmit={async (values, helpers) => {
                // TODO: Build service
                await HttpService.putJson('/api/addresses/' + address.id, values);
                helpers.setSubmitting(false);
                this.props.history.push('./');
            }}
            validate={(address) => {
                address.countryId = Number(address.countryId);
            }}
        >
            {({ isSubmitting }) => (
                <Form>
                    <div className='form-row'>
                        <div className='col-sm-12 col-md-6'>
                            <FrmControl id='firstName' label='Vorname' type='text' className='form-control' maxLength={40} />
                        </div>
                        <div className='col-sm-12 col-md-6'>
                            <FrmControl id='lastName' label='Nachname' type='text' className='form-control' maxLength={60} />
                        </div>
                    </div>
                    <FrmControl id='contact' label='Kontaktperson' type='text' className='form-control' maxLength={30} />

                    <FrmControl id='street' label='Strasse' type='text' className='form-control' maxLength={60} />
                    <div className='form-row'>
                        <div className='col-sm-12 col-md-6'>
                            <FrmControl id='zipCode' label='PLZ' type='text' className='form-control' maxLength={5} />
                        </div>
                        <div className='col-sm-12 col-md-6'>
                            <FrmControl id='city' label='Ort' type='text' className='form-control' maxLength={20} />
                        </div>
                    </div>
                    <FrmControl id='countryId' label='Land' component='select' className='form-control'>
                        <option>&lt; leer &gt;</option>
                        {countriesOptions}
                    </FrmControl>

                    <div className='form-row'>
                        <div className='col-sm-12 col-md-6'>
                            <FrmControl id='phone1' label='Telefon 1' type='text' className='form-control' maxLength={24} />
                        </div>
                        <div className='col-sm-12 col-md-6'>
                            <FrmControl id='mobile' label='Mobile' type='text' className='form-control' maxLength={24} />
                        </div>
                    </div>
                    <div className='form-row'>
                        <div className='col-sm-12 col-md-6'>
                            <FrmControl id='web' label='Internet' type='text' className='form-control' maxLength={50} />
                        </div>
                        <div className='col-sm-12 col-md-6'>
                            <FrmControl id='email' label='E-Mail' type='text' className='form-control' maxLength={50} />
                        </div>
                    </div>

                    <div className='form-group'>
                        <Checkbox id='vendor' label='Lieferant' />
                        <Checkbox id='buyer' label='Abnehmer' />
                    </div>

                    <div className='form-group'>
                        <button type='submit' disabled={isSubmitting} className='btn btn-primary'>
                            Speichern
                        </button>
                        &nbsp;
                        <Link className='btn btn-secondary' to='/addresses/' role='button'>Zur&uuml;ck zur Liste</Link>
                    </div>
                </Form>
            )
            }
        </Formik>;
    }

    private async populateData() {
        const countriesPromise = HttpService.getPaged<Country>('/api/countries');
        const addressPromise = HttpService.get<Address>('/api/addresses/' + this.props.match?.params.id);

        const [countries, address] = await Promise.all([countriesPromise, addressPromise]);

        this.setState({ countries, address, loading: false });
    }
}
