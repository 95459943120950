import React, { Component } from 'react';
import { ListGroup } from 'reactstrap';
import { Icon, IconName } from '../icons/Icon';
import { NavigationListGroupItem } from './abstract/NavigationListGroupItem';
import { LicensedModules } from './LicensedModules';

export class Home extends Component {
    static displayName = Home.name;

    render() {
        return (
            <div>
                <h1>Farmsolution App</h1>

                <p>
                    Die Mobile Datenerfassung und Auswertungen via Smartphone oder Tablet bringt Ihre Daten auch in
                    Ihre Hosentasche.
                </p>

                <h2>Basisdaten</h2>
                <ListGroup>
                    <NavigationListGroupItem to='/addresses/'>
                        <Icon icon={IconName.Addresses} />
                        Adressen
                    </NavigationListGroupItem>
                </ListGroup>

                <h2>Lizenzierte Module</h2>
                <p>
                    Sie haben zur Zeit Lizenzen für die folgenden Module:
                </p>
                <LicensedModules />
            </div >
        );
    }
}
