import { format } from 'date-fns/esm';
import React, { Component } from 'react';
import { RouterProps, useHistory } from 'react-router';
import { CultivationWithUsage } from '../../models/Fieldmanager/cultivationMasterData';
import { LandWithPartialArea } from '../../models/Fieldmanager/land';
import { Staff } from '../../models/staff';
import HttpService from '../../services/HttpService';
import LocalizationService from '../../services/LocalizationService';

export interface ConfirmationProps {
    start?: Date;
    end?: Date;
    staff?: Staff;
    lands: LandWithPartialArea[];
    totalArea: number;
    cultivations: CultivationWithUsage[];
}

interface IState {
    saving: boolean;
}

type ComponentProps = RouterProps & ConfirmationProps;

function getAreaUnit(data: CultivationWithUsage) {
    switch (data.type) {
        case 'SA':
            return 'a';

        default:
            return 'ha';
    }
}

class ConfirmCropDiaryNewEntryRequiresHistory extends Component<ComponentProps, IState> {
    constructor(props: ComponentProps) {
        super(props);

        this.state = { saving: false, };
    }

    public render() {
        const date = this.props.start && this.props.end ?
            <>
                {format(this.props.start!, 'PPP', { locale: LocalizationService.de })}
                {this.props.start?.valueOf() !== this.props.end?.valueOf() ?
                    ' bis ' + format(this.props.end!, 'PPP', { locale: LocalizationService.de })
                    : <></>}
            </> : <>nicht gewählt</>;

        return <div>
            <div className='row'>
                <div className='col-sm-4 col-md-2'><strong>Datum</strong></div>
                <div className='col-sm-8 col-md-10'>{date}</div>
            </div>
            <div className='row mt-2'>
                <div className='col-sm-4 col-md-2'><strong>Personal</strong></div>
                <div className='col-sm-8 col-md-10'>
                    {this.props.staff?.displayName}
                </div>
            </div>
            <div className='row mt-2'>
                <div className='col-sm-4 col-md-2'>
                    <strong>Boden</strong>{' '}
                    <span className='text-muted'>
                        (total {this.props.totalArea} a)
                    </span>
                </div>
                <div className='col-sm-8 col-md-10'>
                    {ConfirmCropDiaryNewEntryRequiresHistory.renderLandsSummary(this.props)}
                </div>
            </div>
            <div className='row mt-2'>
                <div className='col-sm-4 col-md-2'><strong>Arbeit / Position</strong></div>
                <div className='col-sm-8 col-md-10'>
                    {ConfirmCropDiaryNewEntryRequiresHistory.renderCultivationsSummary(this.props)}
                </div>
            </div>
            <div className='row mt-3'>
                <div className='col-12'>
                    <button className='btn btn-primary' onClick={this.createEntries.bind(this)}
                        disabled={!ConfirmCropDiaryNewEntryRequiresHistory.canSave(this.state, this.props)}>
                        Hinzufügen
                    </button>
                </div>
            </div>
        </div>;
    }

    private static canSave(state: IState, props: ConfirmationProps) {
        return !state.saving && // When a write operation is already ongoing, do not allow another one.
            props.start && props.end && props.staff &&
            props.lands.length > 0 &&
            props.cultivations.length > 0 &&
            undefined === props.cultivations.find((item) => !item.amount);
    }

    private static renderLandsSummary(props: ComponentProps) {
        const lands = props.lands.map((land) =>
            <li key={land.id}>
                {land.name + ' '}
                <small>(Fläche: {land.partialArea ?
                    <>{land.partialArea} a von {land.area} a</>
                    : <>{land.area} a</>})</small>
            </li>);

        return <ul className='simple-list'>
            {lands}
        </ul>;
    }

    private static renderCultivationsSummary(props: ComponentProps) {
        const cultivations = props.cultivations.map((data) =>
            <li key={data.id}>
                {data.name + ' '}
                <small>
                    (
                    {data.amount} {data.unit} pro {getAreaUnit(data)}
                    {undefined !== data.usagePrice ? <>, Preis: {data.usagePrice} CHF</> : <></>}
                    )
                </small>
            </li>);

        return <ul className='simple-list'>
            {cultivations}
        </ul>;
    }

    private async createEntries() {
        const creationRequest = {
            start: this.props.start,
            end: this.props.end,
            staffId: this.props.staff!.id,
            land: this.props.lands.map((land) => ({
                id: land.id,
                partialArea: land.partialArea,
            })),
            cultivation: this.props.cultivations.map((cultivation) => ({
                id: cultivation.id,
                amount: cultivation.amount,
                price: cultivation.usagePrice,
                usageId: cultivation.usageId,
                storageId: cultivation.storageId,
            })),
        };

        this.setState({ saving: true });
        try {
            await HttpService.postJson('/api/fieldmanager/cropdiary/new', creationRequest);
            this.props.history.push('../');
        } catch (error) {
            console.error('failed to write entries', error);
        } finally {
            this.setState({ saving: false });
        }
    }
}

export function ConfirmCropDiaryNewEntry(props: ConfirmationProps) {
    return <ConfirmCropDiaryNewEntryRequiresHistory {...props} history={useHistory()} />;
}
