import React, { Component } from 'react';
import { CultivationMasterData, CultivationWithUsage } from '../../models/Fieldmanager/cultivationMasterData';
import { LandWithPartialArea } from '../../models/Fieldmanager/land';
import { Staff } from '../../models/staff';
import { ISwipePane, SwipeContainer } from '../abstract/SwipeContainer';
import { ConfirmationProps, ConfirmCropDiaryNewEntry } from './ConfirmCropDiaryNewEntry';
import { SelectCultivation } from './SelectCultivations';
import { SelectDatesAndStaff } from './SelectDatesAndStaff';
import { SelectLands } from './SelectLands';
import { SetCultivationUsage } from './SetCultivationUsage';
import { SetPartialAreas } from './SetPartialAreas';

interface IProps { }

interface IState {
    paneIndex: number;

    start?: Date,
    end?: Date,
    staff?: Staff;
    lands: LandWithPartialArea[];
    totalArea: number;
    cultivations: CultivationWithUsage[];
}

function getConfirmationPageProps(state: IState): ConfirmationProps {
    return state;
}

export class CropDiaryAddEntry extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = { paneIndex: 0, lands: [], totalArea: 0, cultivations: [], };
    }

    public render() {
        const panes: ISwipePane[] = [
            {
                title: 'Auswahl Datum und Personal',
                body: <SelectDatesAndStaff onSelectionChanged={this.onStartEndSelected.bind(this)} />,
            },
            {
                title: 'Auswahl Boden',
                body: <SelectLands onSelectionChanged={this.onLandsSelected.bind(this)} />,
            },
            {
                title: 'Teilflächen bestimen',
                body: <SetPartialAreas lands={this.state.lands} onSelectionChanged={this.onLandsPartialAreaUpdated.bind(this)} />,
            },
            {
                title: 'Auswahl Arbeit / Positionen',
                body: <SelectCultivation onSelectionChanged={this.onCultivationSelected.bind(this)} />,
            },
            {
                title: 'Arbeit / Positionen ergänzen',
                body: <SetCultivationUsage totalArea={this.state.totalArea} cultivations={this.state.cultivations}
                    onSelectionChanged={this.onCultivationUsageUpdated.bind(this)} />,
            },
            {
                title: 'Bestätigen',
                body: <ConfirmCropDiaryNewEntry {...getConfirmationPageProps(this.state)} />,
            },
        ];

        return <>
            <h4>Tagebuch Pflanzenbau - Neu</h4>
            <SwipeContainer panes={panes} activeIndex={this.state.paneIndex} />
        </>;
    }

    private onStartEndSelected(start: Date, end: Date, staff: Staff) {
        this.setState({ start, end, staff, paneIndex: 1 });
    }

    private onLandsSelected(lands: LandWithPartialArea[]) {
        this.onLandsUpdated(lands, false);
    }

    private onLandsPartialAreaUpdated(lands: LandWithPartialArea[]) {
        this.onLandsUpdated(lands, true);
    }

    private onLandsUpdated(lands: LandWithPartialArea[], withPartial: boolean) {
        const totalArea = lands.reduce((sum, land) => sum += land.partialArea || land.area, 0);
        this.setState({ lands, totalArea, paneIndex: 2 + (withPartial ? 1 : 0) });
    }

    private onCultivationSelected(cultivations: CultivationMasterData[]) {
        this.onCultivationsUpdated(cultivations, false);
    }

    private onCultivationUsageUpdated(cultivations: CultivationWithUsage[]) {
        this.onCultivationsUpdated(cultivations, true);
    }

    private onCultivationsUpdated(cultivations: CultivationWithUsage[], withUsage: boolean) {
        this.setState({ cultivations, paneIndex: 4 + (withUsage ? 1 : 0) });
    }
}
