import React, { ChangeEvent, Component, MouseEvent } from 'react';
import { Input } from 'reactstrap';
import { OrderType } from '../../models/Invoicemanager/orderType';
import HttpService from '../../services/HttpService';
import { ListSelect, ListSelectItem } from '../abstract/ListSelect';
import { Loading } from '../Loading';

interface IProps {
    onSelectionChanged: (description: string, remarks: string | undefined, orderType: OrderType) => void;
}

interface IState {
    loading: boolean;

    description?: string;
    remarks?: string;
    selectedOrderTypeId?: number;
    orderTypes?: OrderType[];
}

export class SelectDescriptionAndOrderType extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = { loading: true, };
    }

    public render() {
        if (this.state.loading) {
            return <Loading />;
        }

        return <>
            <div className='row'>
                <div className='col-sm-6'>
                    <div>
                        <h6>Bezeichnung</h6>
                        <Input name='description' id='description' value={this.state.description} maxLength={255} minLength={1}
                            placeholder='Bezeichnung des Auftrags'
                            onChange={this.handleDescriptionChange.bind(this)} />
                    </div>
                    <div className='mt-3 mb-3'>
                        <h6>Bemerkung</h6>
                        <Input type='textarea' name='remarks' id='remarks' value={this.state.remarks}
                            placeholder='Bemerkung des Auftrags'
                            onChange={this.handleRemarksChange.bind(this)} />
                    </div>
                </div>
                <div className='col-sm-6'>
                    <h6>Typ</h6>
                    {this.renderOrderTypeSelector()}
                </div>
            </div>
            <div className='d-flex justify-content-between mt-3'>
                <div></div>
                <div>
                    <button className='btn btn-primary' onClick={this.onNext.bind(this)} disabled={!this.canMoveNext()}>
                        Weiter &raquo;
                    </button>
                </div>
            </div>
        </>;
    }

    public componentDidMount() {
        this.populateData();
    }

    private async populateData() {
        const orderTypes = await HttpService.getPaged<OrderType>('/api/Invoicemanager/OrderTypes');

        this.setState({ orderTypes, loading: false });
    }

    private renderOrderTypeSelector() {
        const selectors = this.state.orderTypes!.map((orderType) => ({
            key: orderType.id,
            value: orderType.name,
        }));

        return (<ListSelect items={selectors} onSelected={this.onOrderTypeSelected.bind(this)} />);
    }

    private handleDescriptionChange(evt: ChangeEvent<HTMLInputElement>) {
        this.setState({ description: evt.target.value });
    }

    private handleRemarksChange(evt: ChangeEvent<HTMLInputElement>) {
        this.setState({ remarks: evt.target.value });
    }

    private onOrderTypeSelected(selection: ListSelectItem<number>) {
        this.setState({ selectedOrderTypeId: selection.key });
    }

    private onNext(evt: MouseEvent<HTMLButtonElement>) {
        const { description, remarks, selectedOrderTypeId } = this.state;

        if (description && selectedOrderTypeId) {
            const orderType = this.state.orderTypes!.find((orderType) => orderType.id === selectedOrderTypeId);

            this.props.onSelectionChanged(description, remarks, orderType!);
        }
    }

    private canMoveNext() {
        return this.state.description && this.state.selectedOrderTypeId;
    }
}
