import { format } from 'date-fns/esm';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, ListGroup, ListGroupItem } from 'reactstrap';
import { CropDiaryEntry } from '../../models/Fieldmanager/cropDiaryEntry';
import { CultivationMasterData } from '../../models/Fieldmanager/cultivationMasterData';
import { Land } from '../../models/Fieldmanager/land';
import HttpService from '../../services/HttpService';
import LocalizationService from '../../services/LocalizationService';
import Utils from '../../services/Utils';
import { Loading } from '../Loading';

const BatchSize = 10;

interface IProps {
}

interface IState {
    loading: boolean;

    lands: Map<number, Land>;
    cultivations: Map<string, CultivationMasterData>;
    diaryEntries: CropDiaryEntry[];
    hasMore: boolean;
}

const defaultLand: Land = { name: '< nicht gefunden >', id: 0, area: 0, crop: '', type: '' };
const defaultCultivation: CultivationMasterData = { name: '< nicht gefunden >', id: '', group: '', rootId: 0, type: '', typeName: '', unit: '' };

export class ViewRecentCropDiaryEntries extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            loading: true,
            lands: new Map<number, Land>(),
            cultivations: new Map<string, CultivationMasterData>(),
            diaryEntries: [],
            hasMore: true,
        };
    }

    public render() {
        return <>
            <div className='mt-3 d-flex justify-content-between'>
                <div>
                    <h4>Tagebuch Pflanzenbau</h4>
                </div>
                <div>
                    <Link to='/fieldmanager/crop-diary/'>&laquo;&nbsp;Zurück</Link>
                </div>
            </div>
            <ListGroup>{ViewRecentCropDiaryEntries.renderEntries(this.state)}</ListGroup>
            <div className='mt-3 d-flex justify-content-between'>
                <div>
                    {this.state.loading ? <Loading /> :
                        this.state.hasMore ?
                            <Button color='primary' size='sm' onClick={this.loadMore.bind(this)}>Mehr laden</Button> :
                            <small className='text-muted'>Keine weiteren Einträge</small>}
                </div>
                <div>
                    <Link to='/fieldmanager/crop-diary/add/' className='btn btn-primary btn-sm'>Neue Einträge erfassen</Link>
                </div>
            </div>
            {this.state.loading ? <Loading /> : <></>}
        </>;
    }

    public componentDidMount() {
        this.populateInitialData();
    }

    private static renderEntries(state: IState) {
        return state.diaryEntries.map((entry) => <ListGroupItem key={entry.id}>
            {format(new Date(entry.start), 'P', { locale: LocalizationService.de })}, {Utils.safeResolveItemInMap(entry.landId, state.lands, defaultLand).name}
            <div className='text-muted'>
                <small>
                    <strong>{Utils.safeResolveItemInMap(entry.cultivationId, state.cultivations, defaultCultivation).type}</strong>{' '}
                    {Utils.safeResolveItemInMap(entry.cultivationId, state.cultivations, defaultCultivation).name + ', '}
                    {entry.amount} {entry.unit}
                </small>
            </div>
        </ListGroupItem>);
    }

    private async populateInitialData() {
        const diaryPromise = HttpService.getPaged<CropDiaryEntry>('/api/fieldmanager/cropdiary/recent', BatchSize)
        const landsPromise = HttpService.getPaged<Land>('/api/fieldmanager/land');
        const cultivationsPromise = HttpService.getPaged<CultivationMasterData>('api/fieldmanager/cultivationmasterdata');

        const lands = this.state.lands;
        const cultivations = this.state.cultivations;

        (await landsPromise).forEach((land) => lands.set(land.id, land));
        (await cultivationsPromise).forEach((item) => cultivations.set(item.id, item));

        const diaryEntries = await diaryPromise;

        this.setState({
            diaryEntries: diaryEntries,
            lands,
            cultivations,
            hasMore: diaryEntries.length >= BatchSize,
            loading: false,
        });
    }

    private async loadMore() {
        this.setState({ loading: true });
        const currentEntries = this.state.diaryEntries;
        const url = '/api/fieldmanager/cropdiary/recent?afterId=' + encodeURIComponent(currentEntries[currentEntries.length - 1].id);
        const newEntries = await HttpService.getPaged<CropDiaryEntry>(url, BatchSize);

        currentEntries.push(...newEntries);

        this.setState({
            diaryEntries: currentEntries,
            hasMore: newEntries.length >= BatchSize,
            loading: false,
        });
    }
}
