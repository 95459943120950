import React, { Component } from 'react';
import { Route, RouteChildrenProps, Switch } from 'react-router-dom';
import { CardColumns } from 'reactstrap';
import LicenseService, { ILicense } from '../../services/LicenseService';
import { NavigationCard } from '../abstract/NavigationCard';
import { Loading } from '../Loading';
import { CropDiary } from './CropDiary';

interface IProps extends RouteChildrenProps {
}

interface IState {
    loading: boolean;
    license?: ILicense;
}

export class Fieldmanager extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = { loading: true };
    }

    public render() {
        if (this.state.loading) {
            return <Loading />;
        }

        if (!this.state.license?.hasFieldManager) {
            return <div>Feldmanager ist nicht lizenziert.</div>;
        }

        return <>
            <Switch>
                <Route path='/fieldmanager/crop-diary/' component={CropDiary} />
                <Route>
                    Willkommen im Feldmanager Modul!

                    <CardColumns className='mt-3'>
                        <NavigationCard title='Tagebuch Pflanzenbau'
                            text='Verwalten des Pflanzenbau Tagebuches: erstellen von neuen Einträgen, ansehen von bestehenden Einträgen.'
                            to='/fieldmanager/crop-diary/' />
                    </CardColumns>
                </Route>
            </Switch>
        </>;
    }

    public componentDidMount() {
        this.populateData();
    }

    private async populateData() {
        this.setState({ license: await LicenseService.getLicense(), loading: false });
    }
}
