import React, { Component } from 'react';
import { Link, RouteChildrenProps } from 'react-router-dom';
import { AddressMin } from '../../models/address';
import { Article, ArticleWithUsage } from '../../models/Invoicemanager/article';
import { OrderType } from '../../models/Invoicemanager/orderType';
import { Staff } from '../../models/staff';
import { ISwipePane, SwipeContainer } from '../abstract/SwipeContainer';
import { ConfirmNewOrder } from './ConfirmNewOrder';
import { getMandatorId, MandatorParams } from './MandatorParams';
import { SelectArticles } from './SelectArticles';
import { SelectCustomer } from './SelectCustomer';
import { SelectDateAndStaff } from './SelectDateAndStaff';
import { SelectDescriptionAndOrderType } from './SelectDescriptionAndOrderType';
import { SetArticlesDetails } from './SetArticlesDetails';

interface IState {
    paneIndex: number;

    date?: Date,
    staff?: Staff;
    description?: string;
    remarks?: string;
    orderType?: OrderType;
    customer?: AddressMin;
    articles: ArticleWithUsage[];
}

function getConfirmationPageProps(state: IState, props: RouteChildrenProps<MandatorParams>) {
    return { ...state, mandatorId: getMandatorId(props.match?.params)! };
}

export class OrdersAdd extends Component<RouteChildrenProps<MandatorParams>, IState> {
    constructor(props: RouteChildrenProps<MandatorParams>) {
        super(props);

        this.state = { paneIndex: 0, articles: [] };
    }

    public render() {
        const ordersBase = '/invoicemanager/' + this.props.match?.params.mandatorId + '/orders';
        const panes: ISwipePane[] = [
            {
                title: 'Auswahl Datum und Personal',
                body: <SelectDateAndStaff onSelectionChanged={this.onDateAndStaffSelected.bind(this)} />,
            },
            {
                title: 'Bezeichnung und Typ',
                body: <SelectDescriptionAndOrderType onSelectionChanged={this.onDescriptionAndOrderTypeSelected.bind(this)} />,
            },
            {
                title: 'Auswahl Kunde',
                body: <SelectCustomer onSelectionChanged={this.onCustomerSelected.bind(this)} />,
            },
            {
                title: 'Auswahl Artikel / Dienstleistungen',
                body: <SelectArticles mandatorId={getMandatorId(this.props.match?.params)!}
                    onSelectionChanged={this.onArticlesSelected.bind(this)} />,
            },
            {
                title: 'Artikel / Dienstleistungen ergänzen',
                body: <SetArticlesDetails articles={this.state.articles} onChanged={this.onArticlesUsageUpdated.bind(this)} />,
            },
            {
                title: 'Bestätigen',
                body: <ConfirmNewOrder {...getConfirmationPageProps(this.state, this.props)} />,
            },
        ];

        return <>
            <div className='mt-3 d-flex justify-content-between'>
                <div>
                    <h4>Neuer Auftrag</h4>
                </div>
                <div>
                    <Link to={ordersBase}>&laquo;&nbsp;Zurück</Link>
                </div>
            </div>
            <SwipeContainer panes={panes} activeIndex={this.state.paneIndex} />
        </>;
    }

    private onDateAndStaffSelected(date: Date, staff: Staff) {
        this.setState({ date, staff, paneIndex: 1 });
    }

    private onDescriptionAndOrderTypeSelected(description: string, remarks: string | undefined, orderType: OrderType) {
        this.setState({ description, remarks, orderType, paneIndex: 2 });
    }

    private onCustomerSelected(customer: AddressMin) {
        this.setState({ customer, paneIndex: 3 });
    }

    private onArticlesSelected(selected: Article[]) {
        this.setState({ articles: selected, paneIndex: 4 });
    }

    private onArticlesUsageUpdated(articles: ArticleWithUsage[]) {
        this.setState({ articles, paneIndex: 5 });
    }
}
