import React, { Component } from 'react';
import { Badge, ListGroup } from 'reactstrap';
import { Icon, IconName } from '../icons/Icon';
import LicenseService, { ILicense } from '../services/LicenseService';
import { NavigationListGroupItem } from './abstract/NavigationListGroupItem';
import { Loading } from './Loading';
import { NewItemBadge } from './shared';

interface IProps {
}

interface IState {
    loading: boolean;
    license?: ILicense;
}

export class LicensedModules extends Component<IProps, IState> {
    static displayName = LicensedModules.name;

    constructor(props: IProps) {
        super(props);
        this.state = { loading: true };
    }

    public render() {
        return this.state.loading
            ? <Loading />
            : LicensedModules.renderModules(this.state.license as ILicense);
    }

    public componentDidMount() {
        this.populateData();
    }

    private static renderModules(license: ILicense) {
        return (<ListGroup>
            { license.hasAccounting ?
                <NavigationListGroupItem>
                    <Icon icon={IconName.Accounting} />
                    Buchhaltung
                    {' '}<Badge color='warning'>Noch nicht online verfügbar</Badge>
                </NavigationListGroupItem> : <></>}
            { license.hasBilling ?
                <NavigationListGroupItem to='/invoicemanager/'>
                    <Icon icon={IconName.Billing} />
                    Faktura
                    {' '}<NewItemBadge introduced={new Date('2021-10-08')} />
                </NavigationListGroupItem> : <></>}
            { license.hasFieldManager ?
                <NavigationListGroupItem to='/fieldmanager/'>
                    <Icon icon={IconName.Fieldmanager} />
                    Feldmanager
                    {' '}<NewItemBadge introduced={new Date('2021-01-07')} />
                </NavigationListGroupItem> : <></>}
            { license.hasCattleManager ?
                <NavigationListGroupItem>
                    <Icon icon={IconName.Cattle} />
                    Kuhtime
                    {' '}<Badge color='warning'>Noch nicht online verfügbar</Badge>
                </NavigationListGroupItem> : <></>}
        </ListGroup >);
    }

    private async populateData() {
        this.setState({ license: await LicenseService.getLicense(), loading: false });
    }
}
