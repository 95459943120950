import React, { Component } from 'react';
import { Route, RouteChildrenProps, Switch } from 'react-router-dom';
import { CardColumns } from 'reactstrap';
import { NavigationCard } from '../abstract/NavigationCard';
import { MandatorParams } from './MandatorParams';
import { OrdersAdd } from './OrdersAdd';
import { ViewRecentOrders } from './ViewRecentOrders';

export class Orders extends Component<RouteChildrenProps<MandatorParams>> {
    public render() {
        const mandatorPrefix = '/invoicemanager/' + this.props.match?.params.mandatorId;

        return (
            <Switch>
                <Route path='/invoicemanager/:mandatorId/orders/view/' component={ViewRecentOrders} />
                <Route path='/invoicemanager/:mandatorId/orders/add/' component={OrdersAdd} />
                <Route>
                    <h4>Aufträge</h4>
                    <CardColumns>
                        <NavigationCard
                            title='Aufträge Ansehen'
                            text='Neueste Aufträge ansehen.'
                            to={mandatorPrefix + '/orders/view/'} />
                        <NavigationCard
                            title='Neuen Auftrag erfassen'
                            text='Neuen Auftrag erstellen und hinzufügen.'
                            to={mandatorPrefix + '/orders/add/'} />
                        <NavigationCard
                            title='Faktura Start'
                            text='Zurück zum Start von Faktura'
                            to={mandatorPrefix} />
                    </CardColumns>
                </Route>
            </Switch >
        );
    }
}
