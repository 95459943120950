import React, { Component } from 'react';
import { Land } from '../../models/Fieldmanager/land';
import HttpService from '../../services/HttpService';
import { ListSelectItem } from '../abstract/ListSelect';
import { MultiListSelect } from '../abstract/MultiListSelect';
import { Loading } from '../Loading';

interface IProps {
    onSelectionChanged: (selected: Land[]) => void;
}

interface IState {
    loading: boolean;
    lands?: Land[];

    selected: number[];
}

export class SelectLands extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = { loading: true, selected: [], };
    }

    public render() {
        return this.state.loading
            ? <Loading />
            : this.renderSelector();
    }

    public componentDidMount() {
        this.populateData();
    }

    private async populateData() {
        const lands = await HttpService.getPaged<Land>('/api/fieldmanager/land');
        this.setState({ lands, loading: false });
    }

    private renderSelector() {
        const selectors = this.state.lands!.map((land) => ({
            key: land.id,
            value: <>
                {land.name}
                <div className='text-muted'>
                    <small>{land.type}, {land.crop}, {land.area}</small>
                </div>
            </>
        }))

        return <>
            <small>Mehrfachauswahl ist möglich.</small>
            <MultiListSelect items={selectors} onSelected={this.onLandsSelected.bind(this)} />
            <div className='d-flex justify-content-between mt-3'>
                <div></div>
                <div>
                    <button className='btn btn-primary' onClick={this.onConfirmSelection.bind(this)} disabled={this.state.selected.length <= 0}>
                        Weiter &raquo;
                    </button>
                </div>
            </div>
        </>;
    }

    private onLandsSelected(selection: ListSelectItem<number>[]) {
        this.setState({ selected: selection.map((item) => item.key) });
    }

    private onConfirmSelection() {
        const selectedIds = new Set(this.state.selected);
        this.props.onSelectionChanged(this.state.lands!.filter((item) => selectedIds.has(item.id)));
    }
}
