import de from 'date-fns/locale/de';
import { registerLocale, setDefaultLocale } from 'react-datepicker';

class LocalizationService {
    public readonly de = de;
    constructor() {
        registerLocale('de', de);
    }

    public setDefault() {
        setDefaultLocale('de');
    }
}

export default new LocalizationService();
