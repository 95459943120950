import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Staff } from '../../models/staff';
import HttpService from '../../services/HttpService';
import { ListSelect, ListSelectItem } from '../abstract/ListSelect';
import { Loading } from '../Loading';

interface IProps {
    onSelectionChanged: (date: Date, staff: Staff) => void;
}

interface IState {
    date: Date;
    selectedStaffId?: string;

    loading: boolean;
    allStaff?: Staff[];
}

export class SelectDateAndStaff extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        const now = new Date();
        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
        this.state = { date: today, loading: true, };
    }

    public render() {
        if (this.state.loading) {
            return <Loading />;
        }

        return <div className='row'>
            <div className='col-sm-6'>
                <h6>Datum</h6>
                <DatePicker onChange={this.onDatesChanged.bind(this)} selected={this.state.date} inline />
            </div>
            <div className='col-sm-6'>
                <h6>Person</h6>
                {this.renderStaffSelector()}
            </div>
        </div>;
    }

    private renderStaffSelector() {
        const selectors = this.state.allStaff!.map((staff) => ({
            key: staff.id,
            value: staff.displayName,
        }))

        return (<ListSelect items={selectors} onSelected={this.onStaffSelected.bind(this)} />);
    }

    public componentDidMount() {
        this.populateData();
    }

    private onDatesChanged(date: Date) {
        this.setState({ date }, this.onDataUpdated.bind(this));
    }

    private onStaffSelected(selection: ListSelectItem<string>) {
        this.setState({ selectedStaffId: selection.key }, this.onDataUpdated.bind(this));
    }

    private onDataUpdated() {
        const { date, selectedStaffId } = this.state;

        if (date && selectedStaffId) {
            const staff = this.state.allStaff!.find((staff) => staff.id === selectedStaffId);
            this.props.onSelectionChanged(date, staff!);
        }
    }

    private async populateData() {
        const allStaff = await HttpService.getPaged<Staff>('/api/staff');
        this.setState({ allStaff, loading: false });
    }
}
