import React from 'react';

export interface IAuthContext {
    isLoggedIn: boolean;

    setLoggedIn: (loggedIn: boolean) => void;
}

export const AuthContext = React.createContext<IAuthContext>({ isLoggedIn: false, setLoggedIn: () => { } });

type Listener = () => void;

const listeners: { onUnauthenticated: Listener[] } = { onUnauthenticated: [] };

export function addEventListener(type: 'onUnauthenticated', listener: Listener) {
    listeners[type].push(listener);
}

export function raiseUnauthenticated() {
    listeners.onUnauthenticated.forEach((listener) => listener());
}
