import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Staff } from '../../models/staff';
import HttpService from '../../services/HttpService';
import { ListSelect, ListSelectItem } from '../abstract/ListSelect';
import { Loading } from '../Loading';

interface IProps {
    onSelectionChanged: (start: Date, end: Date, staff: Staff) => void;
}

interface IState {
    start: Date;
    end: Date;
    selectedStaffId?: string;

    loading: boolean;
    allStaff?: Staff[];
}

export class SelectDatesAndStaff extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        const now = new Date();
        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
        this.state = { start: today, end: today, loading: true, };
    }

    public render() {
        if (this.state.loading) {
            return <Loading />;
        }

        return <div className='row'>
            <div className='col-sm-6'>
                <h6>Beginn und Ende</h6>
                <div className='mt-2'>
                    <small>Bitte Beginn- und Enddatum auswählen. Für gleiches Datum, zweimal denselben Tag auswählen.</small>
                </div>
                <DatePicker onChange={this.onDatesChanged.bind(this)} startDate={this.state.start} endDate={this.state.end} selectsRange inline />
            </div>
            <div className='col-sm-6'>
                <h6>Personal</h6>
                {this.renderStaffSelector()}
            </div>
        </div>;
    }

    private renderStaffSelector() {
        const selectors = this.state.allStaff!.map((staff) => ({
            key: staff.id,
            value: staff.displayName,
        }))

        return (<ListSelect items={selectors} onSelected={this.onStaffSelected.bind(this)} />);
    }

    public componentDidMount() {
        this.populateData();
    }

    private onDatesChanged([start, end]: [Date, Date]) {
        this.setState({ start, end }, this.onDataUpdated.bind(this));
    }

    private onStaffSelected(selection: ListSelectItem<string>) {
        this.setState({ selectedStaffId: selection.key }, this.onDataUpdated.bind(this));
    }

    private onDataUpdated() {
        const { start, end, selectedStaffId } = this.state;

        if (start && end && selectedStaffId) {
            const staff = this.state.allStaff!.find((staff) => staff.id === selectedStaffId);
            this.props.onSelectionChanged(start, end, staff!);
        }
    }

    private async populateData() {
        const allStaff = await HttpService.getPaged<Staff>('/api/staff');
        this.setState({ allStaff, loading: false });
    }
}
