import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Navbar, NavbarText } from 'reactstrap';

interface IProps { }

interface IState {
    version?: string;
}

export class Footer extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {};
    }

    public render() {
        return <footer>
            <Navbar className='fixed-bottom navbar-dark bg-dark'>
                <Container>
                    <NavbarText>&copy; <a href='http://www.agrosoft.ch'>Agrosoft</a> {new Date().getFullYear()}</NavbarText>
                    <NavbarText className='small'>
                        <Link to='/attributions/'>Impressum</Link>
                        {' / v' + this.state.version}
                    </NavbarText>
                </Container>
            </Navbar>
        </footer>;
    }

    public componentDidMount() {
        this.populateData();
    }

    private async populateData() {
        const data = { version: process.env.REACT_APP_VERSION || 'n/a' }

        this.setState({ version: data?.version });
    }
}
