import React, { Component, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { AuthContext, IAuthContext } from '../Auth';
import farmsolution from '../icons/farmsolution.svg';
import EventsService, { IEvent } from '../services/EventsService';
import { EventServerRequestsUpdate, IServerRequestsUpdate } from '../services/HttpService';
import LoginService from '../services/LoginService';
import { Loading } from './Loading';
import './NavMenu.css';

interface IState {
    collapsed: boolean;
    serverRequestUpdate?: IServerRequestsUpdate;
}

async function doLogout(authContext: IAuthContext) {
    await LoginService.logout();
    authContext.setLoggedIn(false);
}

// TODO: Dedicated component.
function Logout() {
    const authContext = useContext(AuthContext);

    return (<button onClick={() => doLogout(authContext)} className='btn btn-light'>Abmelden</button>)
}

export class NavMenu extends Component<{}, IState> {
    static displayName = NavMenu.name;

    constructor(props: {}) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true
        };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    render() {
        const pendingRequests = this.state.serverRequestUpdate && this.state.serverRequestUpdate.numPending > 0 ?
            <Loading /> : <></>;

        return (
            <header>
                <Navbar className='navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3' light>
                    <Container>
                        <NavbarBrand tag={Link} to='/'><img src={farmsolution} title='Agrosoft Farmsolution' alt='Agrosoft Farmsolution' /></NavbarBrand>
                        {pendingRequests}
                        <NavbarToggler onClick={this.toggleNavbar} className='mr-2' />
                        <Collapse className='d-sm-inline-flex flex-sm-row-reverse' isOpen={!this.state.collapsed} navbar>
                            <ul className='navbar-nav flex-grow'>
                                <NavItem>
                                    <NavLink tag={Link} className='text-dark' to='/invoicemanager/'>Faktura</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} className='text-dark' to='/fieldmanager/'>Feldmanager</NavLink>
                                </NavItem>
                                <NavItem>
                                    <Logout />
                                </NavItem>
                            </ul>
                        </Collapse>
                    </Container>
                </Navbar>
            </header>
        );
    }

    componentDidMount() {
        EventsService.registerCallback<IServerRequestsUpdate>(EventServerRequestsUpdate, this.onServerRequestsUpdate.bind(this));
    }

    private onServerRequestsUpdate(event: IEvent<IServerRequestsUpdate>) {
        this.setState({ serverRequestUpdate: event.args });
    }
}
