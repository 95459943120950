import { ErrorMessage, Field, Form, Formik, FormikHelpers } from 'formik';
import React, { Component } from 'react';
import { AuthContext } from './Auth';
import './Login.css';
import LoginService from './services/LoginService';

interface ILoginData {
    username: string;
    password: string;
}

const connectionStringsDisabled = true;

export default class Login extends Component {
    context!: React.ContextType<typeof AuthContext>;
    static contextType = AuthContext;

    async doSubmit(values: ILoginData, helpers: FormikHelpers<ILoginData>) {
        try {
            await LoginService.login(values.username, values.password);
            this.context.setLoggedIn(true);
        } catch (ex) {
            let errorMessage: string;

            switch (ex.reason) {
                case 'invalid_credentials':
                    errorMessage = 'Anmeldung fehlgeschlagen.';
                    break;

                case 'too_many_requests':
                    errorMessage = 'Zu viele Anmeldeversuche. Bitte versuchen Sie es später erneut.';
                    break;

                case 'unknown':
                default:
                    errorMessage = 'Unbekannter Fehler. Bitte versuchen Sie es erneut.';
                    break;

            }
            helpers.setErrors({ username: errorMessage });
        }
    }

    parseConnectionString(connstr: string, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) {
        const parts = connstr.split(';');

        parts.forEach((part) => {
            const [key, value] = part.split('=');
            const lowerKey = key.toLocaleLowerCase();

            switch (lowerKey) {
                case 'user id':
                case 'uid':
                case 'user':
                    setFieldValue('username', value);
                    break;

                case 'password':
                    setFieldValue('password', value);
                    break;
            }
        });
    }

    render() {
        return (<div className='login'>
            <div className='form-signin'>
                <Formik
                    initialValues={{ username: '', password: '' }}
                    onSubmit={this.doSubmit.bind(this)}
                >
                    {({ dirty, isSubmitting, setFieldValue }) => (
                        <Form>
                            <h1 className='h3 mb-3 font-weight-normal'>Anmelden Bitte</h1>

                            <div className='mb-3'>
                                <ErrorMessage name='username' component='div' className='text-danger' />
                                <label htmlFor='username'>Benutzername</label>
                                <Field type='text' id='username' name='username' className='form-control' placeholder='Benutzername' autoFocus />
                                <label htmlFor='password' className='mt-2'>Passwort</label>
                                <Field type='password' id='password' name='password' className='form-control' placeholder='Passwort' />
                            </div>

                            { !connectionStringsDisabled ? <>
                                <h3 className='font-weight-normal'>oder</h3>
                                <div className='mb-3'>
                                    <label htmlFor='connectionString'>Connection String</label>
                                    <input type='password' id='connectionString' name='connectionString' className='form-control'
                                        onBlur={(evt) => {
                                            this.parseConnectionString(evt.target.value, setFieldValue);
                                            evt.target.value = '';
                                        }}
                                        placeholder='z.B. Server=db.farmsolution.ch;Database=MeineDatenbank;User Id=HansMuster;Password=MeinPasswort' />
                                </div></>
                                : <></>}

                            <button className='btn btn-lg btn-primary btn-block' type='submit' disabled={!dirty || isSubmitting}>Anmelden</button>
                            <p className='mt-5 mb-3 text-muted'>&copy; 2020 - {new Date().getFullYear()} Agrosoft</p>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>);
    }
}