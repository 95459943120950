import Axios from 'axios';

export interface IUser {
    name: string;
}

// NOTE: The LoginService uses Axios directly for better control of request/response flows.

class LoginService {
    public async getUser() {
        const resp = await Axios.get<IUser>('/auth/user');

        switch (resp.status) {
            case 200:
                return Promise.resolve(resp.data);

            case 401:
                return Promise.reject({ reason: 'not_authenticated' });

            default:
                return Promise.reject({ reason: 'unknown' });
        }
    }

    public async login(username: string, password: string) {
        const resp = await Axios.post('/auth/login', { username, password });

        switch (resp.status) {
            case 200:
                return Promise.resolve();

            case 401:
                return Promise.reject({ reason: 'invalid_credentials' });

            case 429:
                return Promise.reject({ reason: 'too_many_requests' });

            default:
                return Promise.reject({ reason: 'unknown' });
        }
    }

    public async logout() {
        await Axios.post('/auth/logout');
    }
}

export default new LoginService();
