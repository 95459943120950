import React from 'react';
import { Badge } from 'reactstrap';

interface NewItemBadgeProps {
    introduced: Date; // when was this item introduced?
    maxVisibleDays?: number; // how many days should the "new" badge be shown? defaults to 90 days.
    children?: React.ReactChild | React.ReactChild[]; // the text / label to show; defaults to 'Neu'.
}

export function NewItemBadge(props: NewItemBadgeProps) {
    const maxVisibleDays = props.maxVisibleDays || 90;
    const visibleUntil = props.introduced.valueOf() + (86400_000 * maxVisibleDays);

    if (Date.now() > visibleUntil) {
        return <></>;
    }

    const inner = props.children || 'Neu';
    return <Badge color='primary'>{inner}</Badge>;
}
