import React, { Component, ReactNode } from 'react';
import { Route, RouteChildrenProps, RouteComponentProps, Switch } from 'react-router-dom';
import { CardColumns } from 'reactstrap';
import { Mandator } from '../../models/Invoicemanager/mandator';
import HttpService from '../../services/HttpService';
import LicenseService, { ILicense } from '../../services/LicenseService';
import { NavigationCard } from '../abstract/NavigationCard';
import { Loading } from '../Loading';
import { ChangeMandatorWithOptionalMandator } from './ChangeMandator';
import { getMandatorId, MandatorParams } from './MandatorParams';
import { Orders } from './Orders';

interface IState {
    loading: boolean;
    license?: ILicense;
    mandator: Mandator | null;
}

export class Invoicemanager extends Component<RouteChildrenProps<MandatorParams>, IState> {
    constructor(props: RouteChildrenProps<MandatorParams>) {
        super(props);

        this.state = { loading: true, mandator: null };
    }

    public render() {
        if (this.state.loading) {
            return <Loading />;
        }

        if (!this.state.license?.hasBilling) {
            return <div>Faktura ist nicht lizenziert.</div>;
        }

        const mandatorId = this.state.mandator?.id || null;
        const mandatorPathSegment = mandatorId ? mandatorId + '/' : '';

        return <>
            <Switch>
                <Route path={['/invoicemanager/:mandatorId/change-mandator/', '/invoicemanager/change-mandator/']}
                    render={ChangeMandatorWithOptionalMandator} />
                <Route path='/invoicemanager/:mandatorId/orders/' component={Orders} />
                <Route>
                    Willkommen im Faktura Modul!

                    <CardColumns className='mt-3'>
                        <NavigationCard title='Mandant wechseln' to={`/invoicemanager/${mandatorPathSegment}change-mandator/`}>
                            <div>Wahl/Wechsel des Mandanten für Faktura.</div>
                            <div className='mt-2'>Aktueller Mandant: <strong>{this.formatCurrentMandator()}</strong></div>
                        </NavigationCard>
                        <NavigationCard title='Aufträge'
                            text='Verwalten der Aufträge: erstellen von neuen und ansehen von bestehenden Aufträgen.'
                            to={`/invoicemanager/${mandatorId}/orders/`} disabled={mandatorId === null} />
                    </CardColumns>
                </Route>
            </Switch>
        </>;
    }

    public componentDidMount() {
        this.populateData();
    }

    private async populateData() {
        const mandatorId = getMandatorId(this.props.match?.params);
        // If we don't have a mandator selected through the URL yet, let's see if we can find at least 1-2 to decide if
        // we can auto-select one.
        const availableMandators = mandatorId === null ?
            await HttpService.getPaged<Mandator>('/api/invoicemanager/mandators/confidential/0', 2) :
            null;

        if (availableMandators !== null && availableMandators.length === 1) {
            // If a mandator has not yet been selected and there's only one to begin with, let's auto-select that one.
            this.props.history.push('/invoicemanager/' + availableMandators[0].id);
            return;
        }

        const pendingLicense = LicenseService.getLicense();
        const pendingMandator = mandatorId ? this.getMandator(mandatorId) : Promise.resolve(null);

        this.setState({ license: await pendingLicense, mandator: await pendingMandator, loading: false });
    }

    private formatCurrentMandator() {
        const mandator = this.state.mandator;

        if (null === mandator) {
            return '< keiner gewählt >';
        } else {
            return mandator.name;
        }
    }

    private async getMandator(id: number) {
        return (await HttpService.get<Mandator>('/api/invoicemanager/mandators/confidential/0/' + id)) || null;
    }
}

export function InvoicemanagerWithOptionalMandator(props: RouteComponentProps<MandatorParams>): ReactNode {
    return <Invoicemanager key={props.match?.params.mandatorId} {...props} />
}
