import React, { Component } from 'react';
import { Col, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Label } from 'reactstrap';
import { CultivationWithUsage } from '../../models/Fieldmanager/cultivationMasterData';
import { FeedPreservation } from '../../models/Fieldmanager/feedPreservation';
import { StorageRoom } from '../../models/Fieldmanager/storageRoom';
import HttpService from '../../services/HttpService';
import Utils from '../../services/Utils';

interface IProps {
    totalArea: number; // in 'a'
    cultivations: CultivationWithUsage[];
    onSelectionChanged: (selected: CultivationWithUsage[]) => void;
}

interface UsageState {
    unit: 'a' | 'ha';
    factor: 100 | 1;
    amount: number;
    isAbs: boolean;
    price?: number;
    usageId?: number;
    storageId?: number;
}

interface IState {
    cultivations: CultivationWithUsage[];
    usages: UsageState[];

    feedPreservation: FeedPreservation[];
    storageRooms: StorageRoom[];
}

function getAreaHa(areaA: number) {
    return Utils.roundDecimal(areaA / 100, 2)!;
}

export class SetCultivationUsage extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = SetCultivationUsage.getDerivedStateFromProps(props) as IState;
    }

    public static getDerivedStateFromProps(nextProps: IProps, curState?: IState): IState | Partial<IState> {
        let result: Partial<IState> = { cultivations: nextProps.cultivations };
        let resetUsages = false;

        if (curState?.feedPreservation) {
            result.feedPreservation = curState.feedPreservation;
        }
        if (curState?.storageRooms) {
            result.storageRooms = curState.storageRooms;
        }

        if (curState && curState.usages) {
            // TODO: Perhaps also consider verifying IDs of cultivation records.
            //       The selection might have changed, but the count might be the same.
            if (curState.usages.length !== nextProps.cultivations.length) {
                resetUsages = true;
            }
        } else {
            resetUsages = true;
        }

        if (resetUsages) {
            result.usages = nextProps.cultivations.map((data) => ({
                unit: data.type === 'SA' ? 'a' : 'ha',
                factor: data.type === 'SA' ? 100 : 1,
                amount: data.amount ? data.amount : 0,
                isAbs: false,
                price: data.usagePrice ? data.usagePrice : data.price,
                usageId: data.type === 'ET' ? data.usageId : undefined,
                storageId: data.type === 'ET' ? data.storageId : undefined,
            }));
        }

        return result;
    }

    public render() {
        const feedPreservations = this.state.feedPreservation?.map((fp) => <option key={fp.id} value={fp.id}>{fp.name}</option>);
        const storageRooms = this.state.storageRooms?.map((s) => <option key={s.id} value={s.id}>{s.name}</option>);

        const forms = this.state.cultivations.map((cultivation, index) => <li key={cultivation.id}>
            <h6>{cultivation.name}</h6>
            <FormGroup row className='mb-1'>
                <Label for={'amount-' + cultivation.id} xs={6} size='sm'>Menge pro {this.state.usages[index].unit}</Label>
                <Col xs={6}>
                    <InputGroup size='sm'>
                        <Input type='number' name={'r' + index.toString()} id={'amount-' + cultivation.id}
                            step={0.1} min={0} value={Utils.roundDecimal(this.getRelAmount(index), 1)}
                            onChange={this.handleRelativeAmountChange.bind(this)} />
                        <InputGroupAddon addonType='append'>
                            <InputGroupText>{cultivation.unit}</InputGroupText>
                        </InputGroupAddon>
                    </InputGroup>
                </Col>
            </FormGroup>
            <FormGroup row className='mb-1'>
                <Label for={'absAmount-' + cultivation.id} xs={6} size='sm'>
                    Gesamtmenge ({getAreaHa(this.props.totalArea)} ha)
                    </Label>
                <Col xs={6}>
                    <InputGroup size='sm'>
                        <Input type='number' name={'a' + index.toString()} id={'absAmount-' + cultivation.id}
                            step={0.1} min={0} value={Utils.roundDecimal(this.getAbsAmount(index), 1)}
                            onChange={this.handleAbsoluteAmountChange.bind(this)} />
                        <InputGroupAddon addonType='append'>
                            <InputGroupText>{cultivation.unit}</InputGroupText>
                        </InputGroupAddon>
                    </InputGroup>
                </Col>
            </FormGroup>
            <FormGroup row className={cultivation.type === 'ET' ? 'mb-1' : ''}>
                <Label for={'price-' + cultivation.id} xs={6} size='sm'>Preis</Label>
                <Col xs={6}>
                    <InputGroup size='sm'>
                        <Input type='number' name={'p' + index.toString()} id={'price-' + cultivation.id}
                            step={0.01} min={0} value={Utils.roundDecimal(this.state.usages[index].price || 0, 2)}
                            onChange={this.handlePriceChange.bind(this)} />
                        <InputGroupAddon addonType='append'>
                            <InputGroupText>CHF</InputGroupText>
                        </InputGroupAddon>
                    </InputGroup>
                </Col>
            </FormGroup>
            {cultivation.type === 'ET' ?
                <>
                    <FormGroup row className='mb-1'>
                        <Label for={'feedPreservation-' + cultivation.id} xs={6} size='sm'>Verwendung</Label>
                        <Col xs={6}>
                            <InputGroup size='sm'>
                                <Input type='select' name={'f' + index.toString()} id={'feedPreservation-' + cultivation.id} required
                                    value={this.state.usages[index].usageId} onChange={this.handleSelectChange.bind(this)}>
                                    <option></option>
                                    {feedPreservations}
                                </Input>
                            </InputGroup>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for={'storageRoom-' + cultivation.id} xs={6} size='sm'>Lagerort</Label>
                        <Col xs={6}>
                            <InputGroup size='sm'>
                                <Input type='select' name={'s' + index.toString()} id={'storageRoom-' + cultivation.id} required
                                    value={this.state.usages[index].storageId} onChange={this.handleSelectChange.bind(this)}>
                                    <option></option>
                                    {storageRooms}
                                </Input>
                            </InputGroup>
                        </Col>
                    </FormGroup>
                </>
                : <></>}
        </li>);

        return <>
            Bitte ergänzen Sie.
            <ul className='simple-list mt-3'>
                {forms}
            </ul>
            <div className='d-flex justify-content-between mt-3'>
                <div></div>
                <div>
                    <button className='btn btn-primary' onClick={this.onConfirmSelection.bind(this)} disabled={!SetCultivationUsage.canContinue(this.state)}>
                        Weiter &raquo;
                    </button>
                </div>
            </div>
        </>;
    }

    componentDidMount() {
        this.populateData();
    }

    private handleRelativeAmountChange(ev: React.ChangeEvent<HTMLInputElement>) {
        const index = Number(ev.target.name.substr(1));
        const usages = this.state.usages;

        usages[index] = { ...usages[index], amount: ev.target.valueAsNumber, isAbs: false, };
        this.setState({ usages });
    }

    private handleAbsoluteAmountChange(ev: React.ChangeEvent<HTMLInputElement>) {
        const index = Number(ev.target.name.substr(1));
        const usages = this.state.usages;

        usages[index] = { ...usages[index], amount: ev.target.valueAsNumber, isAbs: true, };
        this.setState({ usages });
    }

    private handlePriceChange(ev: React.ChangeEvent<HTMLInputElement>) {
        const index = Number(ev.target.name.substr(1));
        const usages = this.state.usages;
        usages[index].price = Math.max(0, ev.target.valueAsNumber);
        this.setState({ usages });
    }

    private handleSelectChange(ev: React.ChangeEvent<HTMLInputElement>) {
        const index = Number(ev.target.name.substr(1));
        const usages = this.state.usages;
        let val = ev.target.value === '' ? undefined : Number(ev.target.value);

        switch (ev.target.name.substr(0, 1)) {
            case 'f':
                usages[index].usageId = val;
                break;

            case 's':
                usages[index].storageId = val;
                break;

            default:
                return;
        }

        this.setState({ usages });
    }

    private static canContinue(state: IState) {
        return undefined === state.usages.find((usage, index) =>
            usage.amount <= 0 ||
            (!usage.usageId && state.cultivations[index].type === 'ET') ||
            (!usage.storageId && state.cultivations[index].type === 'ET'));
    }

    private onConfirmSelection() {
        const cultivations = this.state.cultivations;
        const usages = this.state.usages;

        cultivations.forEach((item, index) => {
            item.amount = Utils.roundDecimal(this.getRelAmount(index), 1);
            item.usagePrice = Utils.roundDecimal(usages[index].price, 2) || undefined;
            item.usageId = usages[index].usageId;
            item.storageId = usages[index].storageId;
        });

        this.setState({ cultivations }, () => this.props.onSelectionChanged(this.state.cultivations));
    }

    private getRelAmount(index: number) {
        const amount = this.state.usages[index];
        if (amount.isAbs) {
            return amount.amount / getAreaHa(this.props.totalArea) / amount.factor;
        }

        return amount.amount;
    }

    private getAbsAmount(index: number) {
        const amount = this.state.usages[index];
        if (amount.isAbs) {
            return amount.amount;
        }

        return amount.amount * getAreaHa(this.props.totalArea) * amount.factor;
    }

    private async populateData() {
        const feedPreservationPromise = HttpService.getPaged<FeedPreservation>('/api/fieldmanager/feedpreservation');
        const storageRoomsPromise = HttpService.getPaged<StorageRoom>('/api/fieldmanager/storagerooms');

        this.setState({ feedPreservation: await feedPreservationPromise, storageRooms: await storageRoomsPromise, });
    }
}
