import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import { Attributions } from './components/Attributions';
import { Addresses } from './components/Base/Addresses';
import { Fieldmanager } from './components/Fieldmanager';
import { Home } from './components/Home';
import { InvoicemanagerWithOptionalMandator } from './components/Invoicemanager';
import { Layout } from './components/Layout';
import './custom.css';

export default class App extends Component {
    static displayName = App.name;

    public render() {
        return (
            <Layout>
                <Switch>
                    <Route path='/fieldmanager/' component={Fieldmanager} />
                    <Route path={['/invoicemanager/:mandatorId/', '/invoicemanager/']} render={InvoicemanagerWithOptionalMandator} />
                    <Route path='/addresses/' component={Addresses} />
                    <Route path='/attributions/' component={Attributions} />
                    <Route path='/' component={Home} />
                </Switch>
            </Layout>
        );
    }
}
