export interface IEvent<T extends any> {
    args: T;
}

export type ICallback<T> = (event: IEvent<T>) => void;

export interface IEventRegistration<T> {
    raise(args: T): void;
}

interface IEventManager<T> extends IEventRegistration<T> {
    addCallback(callback: ICallback<T>): void;
}

class EventsService {
    private eventRegistrations: Map<string, IEventManager<any>> = new Map<string, IEventManager<any>>();

    public registerEvent<T>(name: string): IEventRegistration<T> | undefined {
        if (!this.eventRegistrations.has(name)) {
            const manager = new EventManager<T>();
            this.eventRegistrations.set(name, manager);

            return manager;
        } else {
            console.warn(`The event with name '${name}' has already been registered.`);
        }
    }

    public registerCallback<T>(eventName: string, callback: ICallback<T>) {
        const registration = this.eventRegistrations.get(eventName);

        if (!registration) {
            throw new Error(`No event with the name '${eventName}' is registered.`);
        }

        return registration.addCallback(callback);
    }
}

class EventManager<T> implements IEventManager<T> {
    private callbacks: ICallback<T>[] = [];

    public raise(args: T): void {
        const event: IEvent<T> = { args };

        this.callbacks.forEach((callback) => callback(event));
    }

    public addCallback(callback: ICallback<T>): void {
        this.callbacks.push(callback);
    }
}

export default new EventsService();
