import React, { Component } from 'react';
import { ListGroup, ListGroupItem } from 'reactstrap';
import { ListSelectItem } from './ListSelect';

interface IProps<TKey> {
    items: ListSelectItem<TKey>[];
    onSelected: (items: ListSelectItem<TKey>[]) => void;
}

interface IState<TKey> {
    selectedKeys: Set<TKey>;
}

export class MultiListSelect<TKey extends string | number = string | number> extends Component<IProps<TKey>, IState<TKey>> {
    constructor(props: IProps<TKey>) {
        super(props);

        this.state = { selectedKeys: new Set<TKey>() };
    }

    public render() {
        const listItems = this.props.items.map((item) =>
            <ListGroupItem key={item.key} onClick={() => this.onItemClicked(item)}
                active={this.state.selectedKeys.has(item.key)}
                className='selectable d-flex justify-content-between'>
                <div>{item.value}</div>
            </ListGroupItem>);

        return <ListGroup>
            {listItems}
        </ListGroup>;
    }

    private onItemClicked(item: ListSelectItem<TKey>) {
        const key = item.key;
        const curSelected = this.state.selectedKeys;

        if (curSelected.has(key)) {
            curSelected.delete(key);
        } else {
            curSelected.add(key);
        }

        this.props.onSelected(this.props.items.filter((item) => curSelected.has(item.key)));
        this.setState({ selectedKeys: curSelected });
    }
}
