import React, { Component } from 'react';
import { AddressMin } from '../../models/address';
import HttpService from '../../services/HttpService';
import Utils from '../../services/Utils';
import { ListSelect, ListSelectItem } from '../abstract/ListSelect';
import { Loading } from '../Loading';

interface IProps {
    onSelectionChanged: (customer: AddressMin) => void;
}

interface IState {
    loading: boolean;

    selectedAddressId?: string;
    customers?: AddressMin[];
}

export class SelectCustomer extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = { loading: true, };
    }

    public render() {
        if (this.state.loading) {
            return <Loading />;
        }

        return this.renderCustomerSelector();
    }

    public componentDidMount() {
        this.populateData();
    }

    private async populateData() {
        const customers = await HttpService.getPaged<AddressMin>('/api/addresses/buyers');

        this.setState({ customers, loading: false });
    }

    private renderCustomerSelector() {
        const selectors = this.state.customers!.map((customer) => ({
            key: customer.id,
            value: Utils.addressDisplayNameWithCity(customer),
        }))

        return (<ListSelect items={selectors} onSelected={this.onCustomerSelected.bind(this)} />);
    }

    private onCustomerSelected(selection: ListSelectItem<string>) {
        this.setState({ selectedAddressId: selection.key }, this.onDataUpdated.bind(this));
    }

    private onDataUpdated() {
        const { selectedAddressId } = this.state;

        if (selectedAddressId) {
            const customer = this.state.customers!.find((customer) => customer.id === selectedAddressId);

            this.props.onSelectionChanged(customer!);
        }
    }
}
