import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { ListGroupItem } from 'reactstrap';

interface ItemProps {
    children?: React.ReactNode;
    to?: string;
    className?: string;
}

export function NavigationListGroupItem(props: ItemProps) {
    const history = useHistory();

    if (props.to) {
        return <ListGroupItem onClick={() => history.push(props.to!)} className={'selectable ' + props.className}>
            <Link to={props.to}>{props.children}</Link>
        </ListGroupItem>;
    } else {
        return <ListGroupItem>
            {props.children}
        </ListGroupItem>;
    }
}
