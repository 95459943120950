import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Card, CardBody, CardLink, CardText, CardTitle } from 'reactstrap';

interface CardProps {
    title: React.ReactNode;
    text?: React.ReactNode;
    children?: React.ReactChild | React.ReactChild[];
    to: string;
    disabled?: boolean
}

export function NavigationCard(props: CardProps) {
    const history = useHistory();

    const disabled = !!props.disabled;
    const clickHandler = disabled ? () => { } : () => history.push(props.to);
    const cardClass = disabled ? 'text-muted' : 'selectable';
    const text = props.text ? props.text : props.children;
    const linkTag = disabled ? 'span' : Link;

    return (
        <Card aria-disabled={disabled} onClick={clickHandler} className={cardClass}>
            <CardBody>
                <CardTitle tag='h5'>{props.title}</CardTitle>
                <CardText tag='div'>{text}</CardText>
                <CardLink tag={linkTag} to={props.to}>Weiter &raquo;</CardLink>
            </CardBody>
        </Card>
    );
}
