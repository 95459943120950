import React, { Component } from 'react';

export class Attributions extends Component {
    public render() {
        return <>
            <h1>Impressum</h1>
            <h2>Bilder / Grafiken / Icons</h2>
            <ul>
                <li>Icons erstellt von <a href="https://www.flaticon.com/de/autoren/freepik" title="Freepik">
                    Freepik</a> from <a href="https://www.flaticon.com/de/" title="Flaticon">www.flaticon.com</a></li>
            </ul>
        </>;
    }
}