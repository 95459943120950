import HttpService from './HttpService';

export interface ILicense {
    hasAccounting: boolean;
    hasBilling: boolean;
    hasFieldManager: boolean;
    hasCattleManager: boolean;
}

class LicenseService {
    public getLicense() {
        return HttpService.get<ILicense>('/auth/license');
    }
}

export default new LicenseService();
