import React, { Component } from 'react';
import { Article } from '../../models/Invoicemanager/article';
import HttpService from '../../services/HttpService';
import { ListSelectItem } from '../abstract/ListSelect';
import { MultiListSelect } from '../abstract/MultiListSelect';
import { Loading } from '../Loading';

interface IProps {
    mandatorId: number;
    onSelectionChanged: (selected: Article[]) => void;
}

interface IState {
    loading: boolean;

    articles?: Article[];
    selected: string[];
}

export class SelectArticles extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = { loading: true, selected: [], };
    }

    public render() {
        return this.state.loading
            ? <Loading />
            : this.renderSelector();
    }

    public componentDidMount() {
        this.populateData();
    }

    private async populateData() {
        const articles = await HttpService.getPaged<Article>('/api/Invoicemanager/mandators/confidential/0/' +
            this.props.mandatorId + '/articles');
        this.setState({ articles, loading: false });
    }

    private renderSelector() {
        const selectors = this.state.articles!.map((article) => ({
            key: article.id,
            value: <>
                <strong>{article.name}</strong>, {article.unit}, {article.category}
            </>
        }))

        return <>
            <small>Mehrfachauswahl ist möglich.</small>
            <MultiListSelect items={selectors} onSelected={this.onArticlesSelected.bind(this)} />
            <div className='d-flex justify-content-between mt-3'>
                <div></div>
                <div>
                    <button className='btn btn-primary' onClick={this.onConfirmSelection.bind(this)} disabled={this.state.selected.length <= 0}>
                        Weiter &raquo;
                    </button>
                </div>
            </div>
        </>;
    }

    private onArticlesSelected(selection: ListSelectItem<string>[]) {
        this.setState({ selected: selection.map((item) => item.key) });
    }

    private onConfirmSelection() {
        const selectedIds = new Set(this.state.selected);
        this.props.onSelectionChanged(this.state.articles!.filter((item) => selectedIds.has(item.id)));
    }
}
