import React, { Component } from 'react';
import addressbook from './adressbuch.svg';
import accounting from './buchhaltung.svg';
import cattle from './kuh.svg';
import fieldmanager from './landwirtschaft.svg';
import billing from './rechnung.svg';

export enum Usage {
    Page,
}

export enum IconName {
    Accounting,
    Addresses,
    Billing,
    Cattle,
    Fieldmanager,
}

interface IconProps {
    usage?: Usage;
    icon: IconName;
}

export class Icon extends Component<IconProps> {
    static defaultProps: Partial<IconProps> = {
        usage: Usage.Page,
    };

    public render() {
        const classNames = 'icon ' + this.getUsageClass();

        return <img className={classNames} src={this.getIcon()} alt={this.getIconAlt()} />;
    }

    private getUsageClass() {
        switch (this.props.usage) {
            case Usage.Page:
            default:
                return 'page-icon';
        }
    }

    private getIcon() {
        switch (this.props.icon) {
            case IconName.Accounting:
                return accounting;

            case IconName.Addresses:
                return addressbook;

            case IconName.Billing:
                return billing;

            case IconName.Cattle:
                return cattle;
                
            case IconName.Fieldmanager:
                return fieldmanager;
        }
    }

    private getIconAlt() {
        switch (this.props.icon) {
            case IconName.Accounting:
                return 'Buchhaltung';

            case IconName.Addresses:
                return 'Adressen';

            case IconName.Billing:
                return 'Faktura';

            case IconName.Cattle:
                return 'Kuhtime';
                
            case IconName.Fieldmanager:
                return 'Feldmanager';
        }
    }
}
