import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { CardColumns } from 'reactstrap';
import { NavigationCard } from '../abstract/NavigationCard';
import { CropDiaryAddEntry } from './CropDiaryAddEntry';
import { ViewRecentCropDiaryEntries } from './ViewRecentCropDiaryEntries';

export class CropDiary extends Component {
    public render() {
        return <>
            <Switch>
                <Route path='/fieldmanager/crop-diary/view/' component={ViewRecentCropDiaryEntries} />
                <Route path='/fieldmanager/crop-diary/add/' component={CropDiaryAddEntry} />
                <Route>
                    <h4>Tagebuch Pflanzenbau</h4>
                    <CardColumns>
                        <NavigationCard title='Einträge Ansehen'
                            text='Neueste Einträge im Pflanzenbau Tagebuch ansehen.'
                            to='/fieldmanager/crop-diary/view/' />
                        <NavigationCard title='Neue Einträge erfassen'
                            text='Neue Einträge dem Pflanzenbau Tagebuch hinzufügen.'
                            to='/fieldmanager/crop-diary/add/' />
                    </CardColumns>
                </Route>
            </Switch >
        </>;
    }
}
