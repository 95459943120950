import React, { useEffect, useState } from 'react';
import { LEFT, RIGHT, SwipeEventData, useSwipeable } from 'react-swipeable';
import { Carousel, CarouselItem } from 'reactstrap';

export interface ISwipePane {
    title: React.ReactNode;
    body: React.ReactNode;
}

interface IProps {
    children?: React.ReactNode;
    panes: ISwipePane[];
    activeIndex?: number;
}

export function SwipeContainer(props: IProps) {
    const [activeIndex, setActiveIndex] = useState(props.activeIndex === undefined ? 0 : props.activeIndex);
    const [animating, setAnimating] = useState(false);

    const handlers = useSwipeable({
        onSwipedLeft: onSwiped,
        onSwipedRight: onSwiped,
        preventDefaultTouchmoveEvent: true,
        trackMouse: true,
        trackTouch: true,
    });

    function onSwiped(event: SwipeEventData) {
        switch (event.dir) {
            case LEFT:
                next();
                break;
            case RIGHT:
                previous();
                break;

            default:
                console.error('Unsupported swipe direction:', event.dir);
                break;
        }
    }

    function next() {
        if (animating) {
            return;
        }

        setActiveIndex(Math.min(props.panes.length - 1, activeIndex + 1));
    }

    function previous() {
        if (animating) {
            return;
        }

        setActiveIndex(Math.max(0, activeIndex - 1));
    }

    const panes = props.panes.map((pane, index) => (
        <CarouselItem key={'page-' + index} onExiting={() => setAnimating(true)} onExited={() => setAnimating(false)}>
            <h5>{pane.title}</h5>
            {pane.body}
        </CarouselItem>
    ));

    useEffect(() => {
        if (props.activeIndex !== undefined) {
            setActiveIndex(props.activeIndex);
        }
    }, [props.activeIndex]);

    return <div className='swipe-container' {...handlers}>
        <Carousel activeIndex={activeIndex} next={next} previous={previous} interval={false}>
            {panes}
        </Carousel>
        {panes}
    </div>;
};
