import { AddressMin } from '../models/address';

class Utils {
    public safeResolveItemInMap<K, V>(key: K, map: Map<K, V>, defaultValue: V) {
        return map.get(key) || defaultValue;
    }

    public default<T>(value: T | null | undefined, defaultValue: T) {
        if (value === null || value === undefined) {
            return defaultValue;
        }

        return value;
    }

    public addressDisplayName(addr?: AddressMin) {
        return (this.default(addr?.lastName, '') + ' ' + this.default(addr?.firstName, '')).trim();
    }

    public addressDisplayNameWithCity(addr?: AddressMin) {
        return (this.addressDisplayName(addr) + ', ' + this.default(addr?.city, '')).trim();
    }

    public roundDecimal(num: number | undefined, decimals: number) {
        if (undefined === num) {
            return num;
        }
        else if (decimals <= 0) {
            return Math.round(num);
        }

        const factor = Math.pow(10, decimals);
        return Math.round(num * factor) / factor;
    }
}

export default new Utils();
