import { format } from 'date-fns/esm';
import React, { Component } from 'react';
import { RouteChildrenProps } from 'react-router';
import { Link } from 'react-router-dom';
import { Button, ListGroup, ListGroupItem } from 'reactstrap';
import { OrderWithDetails } from '../../models/Invoicemanager/order';
import HttpService from '../../services/HttpService';
import LocalizationService from '../../services/LocalizationService';
import Utils from '../../services/Utils';
import { Loading } from '../Loading';
import { MandatorParams } from './MandatorParams';

const BatchSize = 10;

interface IState {
    loading: boolean;

    orders: OrderWithDetails[];
    hasMore: boolean;
}

export class ViewRecentOrders extends Component<RouteChildrenProps<MandatorParams>, IState> {
    constructor(props: RouteChildrenProps<MandatorParams>) {
        super(props);

        this.state = { loading: true, orders: [], hasMore: true, };
    }

    public render() {
        const ordersBase = '/invoicemanager/' + this.props.match?.params.mandatorId + '/orders';

        return <>
            <div className='mt-3 d-flex justify-content-between'>
                <div>
                    <h4>Aufträge</h4>
                </div>
                <div>
                    <Link to={ordersBase}>&laquo;&nbsp;Zurück</Link>
                </div>
            </div>
            <ListGroup>{ViewRecentOrders.renderOrders(this.state)}</ListGroup>
            <div className='mt-3 d-flex justify-content-between'>
                <div>
                    {this.state.loading ? <Loading /> :
                        this.state.hasMore ?
                            <Button color='primary' size='sm' onClick={this.loadMore.bind(this)}>Mehr laden</Button> :
                            <small className='text-muted'>Keine weiteren Aufträge</small>}
                </div>
                <div>
                    <Link to={ordersBase + '/add/'} className='btn btn-primary btn-sm'>Neuen Auftrag erfassen</Link>
                </div>
            </div>
            {this.state.loading ? <Loading /> : <></>}
        </>;
    }

    public componentDidMount() {
        this.populateInitialData();
    }

    private static renderOrders(state: Readonly<IState>): React.ReactNode {
        return state.orders.map((order) => <ListGroupItem key={order.id}>
            {format(new Date(order.date), 'P', { locale: LocalizationService.de })},{' '}
            {Utils.addressDisplayNameWithCity(order)}
            <div className='text-muted'>
                <small>
                    <strong>{order.type}</strong>{' '}
                    {order.description}{', '}{order.person}
                </small>
            </div>
        </ListGroupItem>);
    }

    private async populateInitialData() {
        const mandatorId = Number(this.props.match?.params.mandatorId!);
        const orderPromise = HttpService.getPaged<OrderWithDetails>(
            `/api/invoicemanager/mandators/confidential/0/${mandatorId}/orders/recent`, BatchSize)

        const orders = await orderPromise;

        this.setState({
            orders: orders,
            hasMore: orders.length >= BatchSize,
            loading: false,
        });
    }

    private async loadMore() {
        this.setState({ loading: true });
        const currentOrders = this.state.orders;
        const mandatorId = Number(this.props.match?.params.mandatorId!);
        const url = `/api/invoicemanager/mandators/confidential/0/${mandatorId}/orders/recent?afterId=` +
            encodeURIComponent(currentOrders[currentOrders.length - 1].id);
        const newOrders = await HttpService.getPaged<OrderWithDetails>(url, BatchSize);

        currentOrders.push(...newOrders);

        this.setState({
            orders: currentOrders,
            hasMore: newOrders.length >= BatchSize,
            loading: false,
        });
    }
}
